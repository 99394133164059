<template>
  <div class="index">
   <navHeader></navHeader>
    <keep-alive>
      <router-view></router-view>
    </keep-alive>
    <footer-copyright></footer-copyright>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import navHeader from './components/navHeader/navHeader'
import footerCopyright from './components/footerCopyright/footerCopyright'

export default {
  name: 'index',
  components: {
    navHeader,
    footerCopyright
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters([
      'userData',
      'salaryEpibolicComInfo',
      'pageConfig'
    ]),
    routePath () {
      return this.$route.path
    }
  },
  created () {
  },
  methods: {
  },
  watch: {
  }
}
</script>
<style lang="less">
</style>

<style lang="less" scoped>
  @import "index";
</style>
