<template>
  <div class="header">
    <div class="header-container clearfix">
      <router-link tag="div"
        :to="{path: '/index'}"
        class="logo">
        <g-image g-src="page/ywqnHomePage/views/index/components/navHeader/logo.png" alt=""/>
      </router-link>
      <div class="header-nav">
        <div class="clearfix nav-container">
          <template  v-for="item in navDataListMap">
            <template v-if="!item.linkUrl">
              <router-link tag="a"
                           ref="li"
                           class="nav-item"
                           v-if="!item.child"
                           :to="{ path: item.path }"
                           :key="item.index"
                           :class="{on: navKeyMap[routePath] === item.index}">
                {{item.name}}
              </router-link>
              <div :key="item.index"
                   ref="li"
                   :class="{on: navKeyMap[routePath] === item.index}"
                   v-if="item.child"
                   class="nav-item nav-item-down">
                {{item.name}}
                <div class="arrows"></div>
                <div class="down-nav">
                  <router-link tag="div"
                               :to="{ path: ch_item.path }"
                               :class="{on: routePath.indexOf(ch_item.path) > -1}"
                               class="down-nav_li"
                               v-for="ch_item in item.child"
                               :key="ch_item.index">
                    {{ch_item.name}}
                  </router-link>
                </div>
              </div>
            </template>
            <a v-else
               ref="li"
               class="nav-item"
               :target="item.target"
               :key="item.linkUrl"
               :href="item.linkUrl">
              <img :class="item.className"
                   v-if="item.icon"
                   :src="item.icon">
              {{item.name}}
            </a>
          </template>
          <div class="nav-on-line" ref="navOnLine"></div>
        </div>
      </div>
      <template v-if="$PRERENDER_INJECTED">
        <div class="header-login" style="float: right" v-if="!isLogin">
          <div
            @click="$linkInsidePages({path:'/login'})"
            class="login-btn"
          >登录/注册
          </div>
        </div>
        <div class="header-tip" style="float: right" v-if="isLogin">
          <div id="header-nav">
            <div @click="$linkInsidePages({path: '/'})" class="header-info">
              <div v-if="userData.logo === ''&& !userData.logo"
                   class="header-nav-logo default-logo">
              </div>
              <div v-else class="header-nav-logo"
                   :style="`background-image: url(${userData.logo});background-size:100% 100%;`">
              </div>
              <div class="header-nav-info">
                <div class="info-phone">
                  {{userData.phone | subStrStar}}
                </div>
                <div class="info-company">
                  {{userData.companyName | subStr(7)}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getToken } from '@/utils/auth'

const navDataList = [
  {
    path: '/index',
    name: '首页'
  },
  {
    path: '/employment',
    name: '有为灵工'
  },
  {
    path: '/lingyongtongPage',
    name: '灵用通'
  },
  {
    path: '/youzhiRecruit',
    name: '优职招聘'
  },
  {
    path: '/youweiCloud',
    name: '有为云'
  },
  {
    path: '/insurance',
    name: '灵保宝'
  },
  {
    path: '/parkOperation',
    name: '园区运营'
  },
  {
    path: '/aboutUs',
    name: '关于我们'
  }
]

function generateNavKeyMap (arr, parentIndex = null) {
  const listMap = {}
  const keyMap = {}
  let child = {}
  for (let i = 0; i < arr.length; i++) {
    listMap[i] = {
      path: arr[i].path,
      name: arr[i].name,
      index: i,
      linkUrl: arr[i].linkUrl,
      unIcon: arr[i].unIcon,
      icon: arr[i].icon,
      className: arr[i].className,
      target: arr[i].target
    }
    if (arr[i].otherPage && arr[i].otherPage.length) {
      arr[i].otherPage.forEach(p => {
        keyMap[p] = parentIndex || i
      })
    }
    if (arr[i].child) {
      child = generateNavKeyMap(arr[i].child, parentIndex || i)
      listMap[i].child = child.listMap
      Object.assign(keyMap, child.keyMap)
    } else {
      keyMap[arr[i].path] = parentIndex || i
    }
  }
  return { listMap, keyMap }
}
const { listMap, keyMap } = generateNavKeyMap(navDataList)
// 兼容nginx配置
keyMap['/index/'] = keyMap['/index']
// console.info(listMap, keyMap)
export default {
  name: 'navHeader',
  data() {
    return {
      navKeyMap: keyMap, // 用于获取选中态
      navDataListMap: listMap,
      navDataList,
      lisEle: null
    }
  },
  computed: {
    ...mapGetters([
      'userData'
    ]),
    routePath () {
      return this.$route.path
    },

    isLogin () {
      return getToken()
    }
  },
  created() {},
  mounted () {
    this.lisEle = this.$refs.li
    this.routerPush()
  },
  methods: {
    routerPush() {
      const navOnLine = this.$refs.navOnLine
      const index = this.navKeyMap[this.routePath]
      const target = this.lisEle[index].$el || this.lisEle[index]
      navOnLine.style.left = target.offsetLeft + 'px'
      navOnLine.style.width = target.clientWidth + 'px'
    }
  },
  watch: {
    $route() {
      this.routerPush()
    }
  }
}
</script>

<style scoped lang="less">
  @import "~@/styles/customMixin.less";
  .header{
    z-index: 500;
    background-color: #2E2F35;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    min-width: 1280px;
    /*border-bottom: 1px solid #e0e0e0;*/
    .header-container{
      width: 1200px;
      margin: 0 auto;
      height: 80px;
      line-height: 80px;
      display: flex;
      .logo{
        cursor: pointer;
        float: left;
        padding-right: 25px;
        box-sizing: border-box;
        height: 80px;
        overflow: hidden;
        display: flex;
        align-items: center;
        img{
          width: 201px;
          height: 50px;
        }
      }
      .header-nav{
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        text-align: center;
        .nav-container {
          width: 100%;
          vertical-align: top;
          position: relative;
          display: flex;
          align-items: center;
          .nav-item{
            user-select: none;
            font-size: 16px;
            color: #999;
            font-weight: 500;
            cursor: pointer;
            margin:0 20px;
            position: relative;
            display: flex;
            align-items: center;
            transition: all .3s;
            &.nav-item-down{
              .arrows{
                transition: all .3s;
                position: relative;
                transform-origin: 5px 7.5px;
                transform: rotateZ(180deg) ;
                margin-top: -7px;
                top: 50%;
                right: 0;
                margin-left: 5px;
                content: '';
                width: 0;
                height: 0;
                border: 5px solid #FFFFFF;
                border-top-color: transparent!important;
                border-right-color: transparent!important;
                border-left-color: transparent!important;
              }
            }
            .down-nav{
              position: absolute;
              top: 100%;
              right: 50%;
              transform: translateX(50%);
              visibility: hidden;
              opacity: 0;
              transition: all .3s;
              z-index: 888;
              .down-nav_li{
                font-size: 16px;
                color: #fff;
                height: 50px;
                width: 140px;
                line-height: 50px;
                cursor: pointer;
                background-color: rgba(21,36,78,1);
                & + .down-nav_li{
                  border-top: 1px solid #2C2D43;
                }
                &.on, &:hover {
                  color: @primary-color;
                  background-color: #101C3E;
                }
              }
            }
            &:hover{
              color: @primary-color;
              .down-nav{
                visibility: visible;
                opacity: 1;
              }
              &.nav-item-down{
                .arrows{
                  transform: rotateZ(0deg) ;
                  border: 5px solid @primary-color;
                }
              }
            }
            &.on {
              color: @primary-color;
              &.nav-item-down{
                .arrows{
                  border: 5px solid @primary-color;
                }
                &:after{
                  border: 5px solid @primary-color;
                }
              }
            }
          }
          .nav-on-line{
            content: '';
            position: absolute;
            bottom: 0;
            height: 2px;
            background-color: @primary-color;
            transition: all .3s;
          }
        }
      }
      .header-tip{
        display: flex;
        align-items: center;
        height: 80px;
      }
      .header-login{
        float: left;
        .login-btn{
          cursor: pointer;
          margin-left: 20px;
          margin-top: 20px;
          display: inline-block;
          background-color: @btn-primary-bgc;
          width:120px;
          height:40px;
          text-align: center;
          color: @btn-primary-color;
          font-size: 16px;
          line-height: 40px;
          border-radius: 80px;
        }
      }
      // header 下拉导航
      #header-nav {
        height: 100%;
        .header-nav-info {
          color: @btn-minor-bgc;
        }
      }
    }
  }
  @media screen and (max-width: 1400px) {
    .header{
      position: absolute;
    }
  }
</style>
